import React  from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Link} from 'gatsby'

const ContactoOkPage = () => {

	return (
	  <Layout className="page-contacto page-contacto-ok">
	    <SEO 
		    title="Mensaje enviado" 
		    description="Formulario de contacto de DMS empresa de Servicios Informáticos de Barcelona especializada en Soporte, IBM Informix y Software de Planificación de Personal"
		    />

	    <section className="bloque bloque-contacto-main contacto-enviado">
            <h1>Mensaje enviado</h1>
            <p>Tu mensaje se ha enviado correctamente. En breve nos pondremos en contacto contigo.</p>

	        <Link to="/" className="btn btn-accent">Volver al inicio</Link>

	    </section>

	  </Layout>
	);
};

export default ContactoOkPage  
